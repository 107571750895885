import actionTypes from '@actions/actionTypes';
import {snackbarConfig} from '@utils/constants';
import { setData} from '@utils/common/storage';
import { endsWith } from '@collections/lodash';
import theme from '../theme';



let appLocalPreloadedState = {};
if (appLocalPreloadedState && appLocalPreloadedState.length){
    appLocalPreloadedState = JSON.parse(appLocalPreloadedState);
}


const appPreloadedState = {
    appLoading: false,
    activeTheme: 'light',
    activeThemeColor: 'blue',
    drawerOpen: 'true',
    snackbar: {
        visible: false,
        ...snackbarConfig,
    },
    accountImpersonated: {},
    ...appLocalPreloadedState,
};


const authenticationReducer = (state = appPreloadedState, action) => {
    const {payload} = action;
    let reducerState;

    if (endsWith(action.type, 'FAILURE') || endsWith(action.type, 'SUCCESS')){
        reducerState = {
            ...state,
            appLoading: false,
        };
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;
    }

    switch (action.type) {
    case actionTypes.SHOW_SNACKBAR:
        reducerState = {
            ...state,
            snackbar: {
                visible: true,
                ...snackbarConfig,
                ...payload,
            }
        };
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.HIDE_SNACKBAR:
        reducerState = {
            ...state,
            snackbar: { ...state.snackbar, message: '', visible: false },
        };
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.APP_LOADING:
        reducerState = {
            ...state,
            appLoading: payload,
        };

        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.TOGGLE_THEME:
        reducerState = {
            ...state,
            activeTheme: state.activeTheme === 'light' ? 'dark': 'light',
        };

        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.CHANGE_THEME_COLOR: {
        let themesArray = ['red', 'green', 'violet','blue'];
        themesArray = themesArray.filter((e) => e!==state.activeThemeColor);
        var randomNumber = Math.floor(Math.random()*themesArray.length);
        
        reducerState = {
            ...state,
            activeThemeColor: themesArray[randomNumber],
        };
    
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;
    }

    case actionTypes.SET_THEME_COLOR: {
        reducerState = {
            ...state,
            activeThemeColor: payload,
        };
    
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;
    }   

    case actionTypes.TOGGLE_DRAWER:
        reducerState = {
            ...state,
            drawerOpen: payload,
        };

        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.SET_IMPERSONATION:
        reducerState = {
            ...state,
            accountImpersonated: payload,
        };
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;

    case actionTypes.REMOVE_IMPERSONATION:
        reducerState = {
            ...state,
            accountImpersonated: {},
        };
        setData(process.env.APP_CONFIG, reducerState);
        return reducerState;


    default:
        return state;
    }
};


export default authenticationReducer;
