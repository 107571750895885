import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import AppRouter from '@routers';
import './style.css';


const App = () => {
    const {activeTheme, activeThemeColor} = useSelector(state => state.config);
    return (
        <Router>
            <CssBaseline />
            <ThemeProvider theme={theme(activeTheme, activeThemeColor)}> 
                <AppRouter />
            </ThemeProvider>
        </Router>
    );
};
export default App;
