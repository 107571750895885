import React from 'react';
import MyComponent from 'react-fullpage-custom-loader';
import {random, size} from '@collections/lodash';
import { backdropLoader, loadingMessages } from '@utils/constants';

import { useTheme } from '@mui/material/styles';


const SimpleBackdrop = () => {
    const theme = useTheme();


    const loader = () => {
        return (
            <MyComponent
                sentences={loadingMessages}
                loaderSize={'null'}
                fadeIn
                wrapperBackgroundColor={theme.colors.backgroundLight}
                loaderType={backdropLoader[random(0, size(backdropLoader)-1)]}
                textStyles={{color: theme.palette.secondary.main, marginTop: theme.spacing(10)}}
                color={theme.palette.primary.main}
            />
        );
    };



    return loader();
};


export default SimpleBackdrop;


