export const GENERAL_ERROR_TITLE_MSG = 'Something Went Wrong';
export const GENERAL_ERROR_DESCRIPTION_MSG = 'Please try to refresh the page';
export const GENERAL_ERROR_FOOTER_MSG = 'If problem still exist, Try again after sometime!';
export const ERROR_PAGE_NOT_EXISTS_TITLE = 'Page you are looking doesn\'t exist';
export const ERROR_PAGE_NOT_EXISTS_DESCRIPTION = 'Go to Home Page';
export const WEB_SOURCE = 'web';
export const USER_ROLE = 'STUDENT';
export const APP_BAR_HEIGHT = '9vh';
export const MOB_DRAWER_BLEEDING_HEIGHT = '9vh';
export const RESEND_OTP_TIMER = 30;

export const ENCRYPTION_KEY = `${process.env.ENCRYPTION_KEY}` || 'Encryption';
export const DRAWER_WIDTH = 200;
export const TABLE_PAGE_CONFIG = {
    order: 'desc',
    page: 0,
    orderBy: 'created_at',
    rowsPerPage: 10,
};

export const ALPHABETS_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';


export const CUSTOM_DROPDOWN_CONFIG= {
    create: true,
    multipleSelect: false,
    showCheckbox: false,
    limitTags: 2,
    dropdownLabel: 'label',
};

export const customCheckBoxConfig= {
    labelPlacement: 'end'
};

export const snackbarConfig = {
    transitionTime: 5000,
    message: '',
    anchorOrigin: {vertical: 'bottom', horizontal: 'right' },
    transitionType: 'fade',
};

export const mapDaysOfWeek = {
    0: 'sun',
    1: 'mon',
    2: 'tues',
    3: 'wed',
    4: 'thur',
    5: 'fri',
    6: 'sat',
};


export const backdropLoader = [
    'ball-8bits',
    'ball-atom',
    'ball-beat',
    'ball-circus',
    'ball-climbing-dot',
    'ball-clip-rotate',
    'ball-clip-rotate-multiple',
    'ball-clip-rotate-pulse',
    'ball-elastic-dots',
    'ball-fall',
    'ball-fussion',
    'ball-grid-beat',
    'ball-grid-pulse',
    'ball-newton-cradle',
    'ball-pulse',
    'ball-pulse-rise',
    'ball-pulse-sync',
    'ball-rotate',
    'ball-running-dots',
    'ball-scale',
    'ball-scale-multiple',
    'ball-scale-pulse',
    'ball-scale-ripple',
    'ball-scale-ripple-multiple',
    'ball-spin',
    'ball-spin-clockwise',
    'ball-spin-clockwise-fade',
    'ball-spin-clockwise-fade-rotating',
    'ball-spin-fade',
    'ball-spin-fade-rotating',
    'ball-spin-rotate',
    'ball-square-clockwise-spin',
    'ball-square-spin',
    'ball-triangle-path',
    'ball-zig-zag',
    'ball-zig-zag-deflect',
    'cube-transition',
    'fire',
    'line-scale',
    'line-scale-party',
    'line-scale-pulse-out',
    'line-scale-pulse-out-rapid',
    'line-spin-clockwise-fade',
    'line-spin-clockwise-fade-rotating',
    'line-spin-fade',
    'line-spin-fade-rotating',
    'pacman',
    'square-jelly-box',
    'square-loader',
    'square-spin',
    'timer',
    'triangle-skew-spin',
];

export const loadingMessages = [
    'Please Wait... \n 640K ought to be enough for anybody',
    'Please Wait... \n the architects are still drafting',
    'Please Wait... \n the bits are breeding',
    'Please Wait... \n we\'re building the buildings as fast as we can',
    'Please Wait... \n a few bits tried to escape, but we caught them',
    'Please Wait... \n and dream of faster computers',
    'Please Wait... \n checking the gravitational constant in your locale',
    'Please Wait... \n at least you\'re not on hold',
    'Please Wait... \n the server is powered by a lemon and two electrodes',
    'Please Wait... \n we\'re testing your patience',
    'Please Wait... \n as if you had any other choice',
    'Please Wait... \n why don\'t you order a sandwich?',
    'Please Wait... \n while the satellite moves into position',
    'Please Wait... \n the bits are flowing slowly today',
    'Please Wait... \n it\'s still faster than you could draw it',
];
