import React from 'react';
import RetryableLazy from 'react-lazy-retry';

// const lazyWithRetry = (componentImport) =>
//     lazy(async () => {
//         const pageHasAlreadyBeenForceRefreshed = JSON.parse(
//             window.localStorage.getItem(
//                 'page-has-been-force-refreshed'
//             ) || 'false'
//         );

//         try {
//             const component = await componentImport();

//             window.localStorage.setItem(
//                 'page-has-been-force-refreshed',
//                 'false'
//             );

//             return component;
//         } catch (error) {
//             if (!pageHasAlreadyBeenForceRefreshed) {
//                 // Assuming that the user is not on the latest version of the application.
//                 // Let's refresh the page immediately.
//                 window.localStorage.setItem(
//                     'page-has-been-force-refreshed',
//                     'true'
//                 );
//                 return window.location.reload();
//             }

//             // The page has already been reloaded
//             // Assuming that user is already using the latest version of the application.
//             // Let's let the application crash and raise the error.
//             throw error;
//         }
//     });

//public apps
export const ActivityPage = RetryableLazy(() => import('@pages/activity'));
export const ResourcePage = RetryableLazy(() => import('@pages/resource'));
export const RedirectPage = RetryableLazy(() => import('@pages/redirect'));
export const DefaultPage = RetryableLazy(() => import('@pages/default'));

const apps = {
    home: { path: '/', component: <ActivityPage/>, },
    activities_page: { path: '/page/activities', component: <ActivityPage/>, },
    activities: { path: '/pages/activities', component: <ActivityPage/>, },
    resources: { path: '/pages/activities/:class_ref/:activity_name/resources', component: <ResourcePage/>, },
    redirect: { path: '/pages/redirect', component: <RedirectPage/>, },
    default: { path: '*', component: <DefaultPage/>, },
};


export default apps;
