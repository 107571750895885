
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from '@components/ErrorBoundary';
import getStore from './store';
import App from './App';
const store = getStore();

render(
    <Provider store={store}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('app')
);
