import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '../reducers';
import thunk from 'redux-thunk';

import { saveReduxData } from '@utils/common/storage';
import throttle from 'lodash/throttle';



const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
        collapsed: true
    });
    middlewares.push(logger);
}

const bindMiddleware = () => {
    return applyMiddleware(...middlewares);
};


// const enhancer = composeWithDevTools({
//     // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
// })(bindMiddleware());


// const persistedState = loadReduxData();
const persistedState = {};
function getStore() {
    let store, composeEnhancer;

    if (process.env.NODE_ENV !== 'production') {
        composeEnhancer = composeWithDevTools({
            name: 'Redux',
            realtime: true,
            trace: true,
            traceLimit: 25
        });
    } else {
        composeEnhancer = global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    store = createStore(
        reducers,
        persistedState,
        composeEnhancer(bindMiddleware()),
    );

    
    

    store.subscribe(throttle(() => {
        saveReduxData(store.getState());
    }, 1000));

    if (module && module['hot']) {
        module['hot'].accept('../reducers', () => {
            const nextReducer = require('../reducers/index').default;
            store.replaceReducer(nextReducer);
        });
    }
    return store;
}

export default getStore;

