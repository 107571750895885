import { red, deepOrange } from '@mui/material/colors';

export const appLightPalette = {
    primary: {
        main: '#2271BA',
        300: '#D6E7F7',
    },
    secondary: {
        main: '#a2258d',
        300: '#c3abd9',
        600: '#8758B3',
        800: '#43295b',
    },
    error: {
        main: red.A400,
        800: red.A700,
    },
    background: {
        default: '#fff',
    },
    grey: {
        300: '#494D5F',
        400: '#A8ACAA',
        main: '#70777D',     
    },

    info: {
        main: '#4460E1',
        100: '#e8f0fe',
    },
    warning: {
        main: deepOrange.A400,
    },
    success: {
        300: '#4caf50',
        // light: '#4caf50',
        main: '#2EBA3C',
        // dark: '#1b5e20'
        700: '#1b5e20',
    },
};

export const appLightColors = {
    defaultThemeColor: '#097EA3',
    green: '#5EC994',
    drawerText: '#2b2e64',
    textLight: '#fff',
    darkest: 'black',
    transparent: 'transparent',
    background: '#097EA3',
    backgroundLight: '#EDF2F5',
    lightest: '#fff',
    drawer: '#fff',
    tableBg: '#fff',
    tableBorder: 'rgba(224, 224, 224, 1)',
    paperBorder: '#e4e9f0',
    optionColor: '#353434cf',
    paperBoxShadow: '0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%)',
    headerColor: 'rgba(255, 255, 255, 0.2)',
    textColor: '#303335',
    textLightColor: '#fff',
    borderColor: 'rgba(255, 255, 255, 0.25)',
    normalButtonColor: '#fff',
    noramlButtonDisabledColor: '#CED9E3',
    actionButtonColor: '#F3D018',
    actionColor: '#F3D018',
    actionDisabledButtonColor: '#CED9E3',
    backgroundOpaque: '',
    lightBackgroundOpaque: 'rgba(255, 255, 255, 0.12)',

    // design colors
    primary: '#2271BA',
    black: '#303335',
    white: '#fff',
    grey: '#70777D',
    lightGrey: '#CED9E3',
    greyLightest: '#EDF2F5',
    success: '#2EBA3C',
    lightBlue: '#D6E7F7',
    error: red.A400,
};

export const appCustomColors = {
    red: {
        defaultThemeColor: '#CC5C43',
        background: '#CC5C43',
        backgroundOpaque: 'rgba(204, 92, 67, 0.4)'
    },
    green: {
        defaultThemeColor: '#09A375',
        background: '#09A375',
        backgroundOpaque: 'rgba(9, 163, 117, 0.4)'
    },
    violet: {
        defaultThemeColor: '#33229F',
        background: '#33229F',
        backgroundOpaque: 'rgba(51, 34, 159, 0.4)'
    },
    blue: {
        defaultThemeColor: '#097EA3',
        background: '#097EA3',
        backgroundOpaque: 'rgba(9, 126, 163, 0.4)'
    }
};