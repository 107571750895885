import mixpanel from 'mixpanel-browser';
import { getData} from '@utils/common/storage';

export const mixPanelEvents = {

    trackEvent: (eventName, props={}) => {
        let userData = getData(process.env.STORAGE_USER_INFORMATION);
        if (userData) {
            userData = JSON.parse(userData);
        }
        if (userData  && userData.user && userData.user.user_uuid) {
            props['user_uuid'] = userData.user?.user_uuid;
        }
        for (let key of Object.keys(props)) {
            if (props[key]===undefined || props[key]===null) {
                delete props[key];
            }
        }
        mixpanel.track(eventName, {...props});
    },

    setUserProperties: (uuid, props={}) => {
        mixpanel.identify(uuid);
        mixpanel.people.set({...props, user_uuid: uuid});
    },

    setUserProperties2: (props={}) => {
        let userData = getData(process.env.STORAGE_USER_INFORMATION);
        if (userData) {
            userData = JSON.parse(userData);
        }

        if (userData  && userData.user && userData.user.user_uuid) {
            props['user_uuid'] = userData.user?.user_uuid;
        }

        if (userData.user && userData.user.user_uuid) {
            mixPanelEvents.setUserProperties(userData.user.user_uuid, {...props, user_uuid: userData.user.user_uuid});
        }
    },

    init: (apiKey, debug) => mixpanel.init(apiKey, {debug}),
    link_clicked: (page_name, props={}) => mixPanelEvents.trackEvent('Link Clicked', {page: page_name, ...props}),
    page_opened: (page_name, props={}) => mixPanelEvents.trackEvent('Page Opened', {page: page_name, ...props}),
    resource_opened: (props={}) => mixPanelEvents.trackEvent('Resource Opened', {...props}),
    error_displayed: (page_name, props={}) => mixPanelEvents.trackEvent('Error Displayed', {page: page_name, ...props}),
    message_displayed: (page_name, props={}) => mixPanelEvents.trackEvent('Message Displayed', {page: page_name, ...props}),
};