import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { values, map } from '@collections/lodash';
import Backdrop from '@components/layout/Backdrop';

import apps from '@apps';
import useOnMount from '@admin/useOnMount';


const AppRoutes = () => {
    useOnMount();

    return (
        <Suspense fallback={<Backdrop />}>
            <Routes>
                {map(values(apps), (route, index) => {
                    return (
                        <Route key={index} exact path={route.path} element={route.component}/>
                    );
                })}
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
