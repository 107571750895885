var CryptoJS = require('crypto-js');
import {ENCRYPTION_KEY} from '@utils/constants';
import {size} from 'lodash';
import cookie from 'react-cookies';

const storeInStorage = (key, value) => {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    value = CryptoJS.AES.encrypt(value, ENCRYPTION_KEY).toString();
    localStorage.setItem(key, value);
};

const getFromStorage = (key) => {
    let ciphertext = localStorage.getItem(key);
    if (ciphertext && ciphertext.length){
        const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else return '';
};

const getFromHybridStorage = (key) => {
    let ciphertext = localStorage.getItem(key);
    if (!ciphertext) {
        ciphertext =  cookie.load(key);
    }
    if (ciphertext && ciphertext.length){
        const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else return '';
};


export const getFromCookie = (key) => {
    return cookie.load(key);
};

export const removeFromCookie = (key) => {
    cookie.remove(key);
};

const removeFromStorage = (key) => {
    localStorage.removeItem(key);
};

export const setToken = (tokenObj) => {
    storeInStorage('accessToken', tokenObj.accessToken);
    storeInStorage('refreshToken', tokenObj.refreshToken);
};

export const getAccessToken = () => {
    return getFromStorage('accessToken');
};

export const getRefreshToken = () => {
    return getFromStorage('refreshToken');
};

export const clearAccessRefreshToken = () => {
    return (
        removeFromStorage('accessToken') &&
        removeFromStorage('refreshToken')
    );
};

export const setData = (key, value) => storeInStorage(key, value);
export const getData = (key) => getFromStorage(key);
export const getHybridData = (key) => getFromHybridStorage(key);
export const removeData = (key) => removeFromStorage(key);

export const saveReduxData = (state) => {
    try{
        const serializedState = JSON.stringify(state);
        storeInStorage(process.env.REDUX_STORE, serializedState);
    } catch(err){
        return undefined;
    }
};

export const loadReduxData = () => {
    try {
        const serializedState = getData(process.env.REDUX_STORE); 
        if (size(serializedState)) {
            return JSON.parse(serializedState);
        }
        return {};
    } catch (err) {
        return {};
    }

};

export const storeInSession = (key, value) => {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    value = CryptoJS.AES.encrypt(value, ENCRYPTION_KEY).toString();
    sessionStorage.setItem(key, value);
};

export const getFromSession = (key) => {
    let ciphertext = sessionStorage.getItem(key);
    if (ciphertext && ciphertext.length){
        const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
        const value = bytes.toString(CryptoJS.enc.Utf8);
        return value;
    } else return '';
};
