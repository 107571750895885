import { useEffect } from 'react';
import {isEmpty} from '@collections/lodash';

import { mixPanelEvents } from '@utils/common/events';
import { storeInSession, getFromSession } from '@utils/common/storage';




const useOnMount = () => {
    useEffect(() => {
        mixPanelEvents.init(process.env.MIX_PANEL_TOKEN);
        const mixpanelSession = getFromSession(process.env.SESSION_STORAGE_KEY);
        // mixPanelEvents.session_start();
        if (isEmpty(mixpanelSession)){
            storeInSession(process.env.SESSION_STORAGE_KEY, {
                timestamp: new Date().getTime(),
            });
        }
    },[]);    
};

export default useOnMount;