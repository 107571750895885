import React from 'react';
import {
    GENERAL_ERROR_TITLE_MSG,
    GENERAL_ERROR_DESCRIPTION_MSG,
    GENERAL_ERROR_FOOTER_MSG,
} from '@utils/constants';
import NotFoundImage from '@assets/404.webp';
import { mixPanelEvents } from '@utils/common/events';
import styles from './styles';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { children } = this.props;
        const { hasError, error, errorInfo } = this.state;

        if (hasError) {
            mixPanelEvents.error_displayed('default', { error: error, error_info: errorInfo });
            // Error path
            return (
                <div style={styles.errorRoot}>
                    <img style={styles.errorImage} src={NotFoundImage} alt=''/>
                    <h1 style={styles.errorTitle}>{GENERAL_ERROR_TITLE_MSG}</h1>
                    <h4 style={styles.errorDescription}>{GENERAL_ERROR_DESCRIPTION_MSG}</h4>
                    <p style={styles.errorDisclaimer}>{GENERAL_ERROR_FOOTER_MSG}</p>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
