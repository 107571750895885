import mapKeys from 'lodash/mapKeys';

export default mapKeys([
    'SHOW_SNACKBAR',
    'HIDE_SNACKBAR',
    'APP_LOADING',

    'SHOW_ERROR',
    'CLEAR_ERROR'
]);
